<template>
  <b-nav tabs>
    <template v-for="(route, index) in routes">
      <b-nav-item :to="route.to" :key="index" :active="route.isActive">
        {{ route.name }}
      </b-nav-item>
    </template>
  </b-nav>
</template>

<script>
export default {
  name: 'ClubTabs',
  props: {
    clubId: {
      type: [ String, Number ]
    }
  },
  computed: {
    routes() {
      const {
        CLUB_VIEW, CLUB_TRAINER_LIST, CLUB_TARIFF_LIST, CLUB_SCHEDULE,
        CLUB_ROOM_LIST, CLUB_ROOM_ADD, CLUB_ROOM_EDIT,
        CLUB_NEWS_LIST, CLUB_NEWS_ADD, CLUB_NEWS_EDIT,
        CLUB_PROMOTION_LIST, CLUB_PROMOTION_ADD, CLUB_PROMOTION_EDIT
      } = this.$routeName;
      const { name } = this.$route;
      return [
        {
          name: 'Основная информация',
          to: { name: CLUB_VIEW, params: { clubId: this.clubId }},
          isActive: name === CLUB_VIEW
        },
        {
          name: 'Помещения',
          to: { name: CLUB_ROOM_LIST, params: { clubId: this.clubId }},
          isActive: name === CLUB_ROOM_LIST || name === CLUB_ROOM_ADD || name === CLUB_ROOM_EDIT
        },
        {
          name: 'Тренеры',
          to: { name: CLUB_TRAINER_LIST, params: { clubId: this.clubId }},
          isActive: name === CLUB_TRAINER_LIST
        },
        {
          name: 'Тарифы',
          to: { name: CLUB_TARIFF_LIST, params: { clubId: this.clubId }},
          isActive: name === CLUB_TARIFF_LIST
        },
        {
          name: 'Расписание',
          to: { name: CLUB_SCHEDULE, params: { clubId: this.clubId }},
          isActive: name === CLUB_SCHEDULE
        },
        { 
          name: 'Новости', 
          to: { name: CLUB_NEWS_LIST, params: { clubId: this.clubId }},
          isActive: name === CLUB_NEWS_LIST || name === CLUB_NEWS_ADD || name === CLUB_NEWS_EDIT
        },
        { 
          name: 'Акции', 
          to: { name: CLUB_PROMOTION_LIST, params: { clubId: this.clubId }},
          isActive: name === CLUB_PROMOTION_LIST || name === CLUB_PROMOTION_ADD || name === CLUB_PROMOTION_EDIT
        }
      ];
    }
  }
};
</script>
