export const comfortInfoList = {
    POOL: { "comfort": "POOL", name: "бассейн", "img": require('@img/icon/comfort/pool.svg') },
    TOWL: { "comfort": "TOWL", name: "полотенца", "img": require('@img/icon/comfort/towel.svg') },
    DaNT: { "comfort": "DaNT", name: "24/7", "img": require('@img/icon/comfort/dant.svg') },
    SAUN: { "comfort": "SAUN", name: "сауна", "img": require('@img/icon/comfort/sauna.svg') },
    WTER: { "comfort": "WTER", name: "вода", "img": require('@img/icon/comfort/water.svg') },
    PRKN: { "comfort": "PRKN", name: "парковка", "img": require('@img/icon/comfort/parking.svg') },
    SHWR: { "comfort": "SHWR", name: "душ", "img": require('@img/icon/comfort/shower.svg') },
    DFLT: { "comfort": "DFLT", name: "default", "img": require('@img/icon/comfort/default.svg') },
};

export function getComfortItem(name) {
  return comfortInfoList[name] || comfortInfoList['DFLT'];
}
