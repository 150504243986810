<template>
  <ClubTemplate :clubId="clubId">
    <div class="club-panel">
      <div class="club-panel__vue">
        <div class="club-panel__photo">
          <img :src="club.img" alt="">
        </div>
        <div class="club-panel__content">
          <h3 class="club-panel__title">{{ club.name }}</h3>
          <p class="club-panel__adress">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path clip-rule="evenodd" d="M8.24 14.392a7.678 7.678 0 1115.356.052v.087c-.052 2.756-1.591 5.304-3.479 7.295a20.18 20.18 0 01-3.59 2.957.93.93 0 01-1.218 0 19.819 19.819 0 01-5.052-4.73 9.826 9.826 0 01-2.018-5.635v-.026z" stroke="#5C5C5C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><circle cx="15.917" cy="14.539" r="2.461" stroke="#5C5C5C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
            {{ club.address }}
          </p>
          <div class="club-panel__text">{{ club.text }}</div>
          <div class="club-panel__more" @click="showModal">Подробнее…</div>
        </div>
      </div>
      <div class="club-panel__block mb-md">
        <div class="club-panel__label">Направления</div>
        <div class="club-panel__tag-list">
          <template v-for="(item, index) in club.tags">
            <div class="club-panel__tag" :key="'tags-' + index">{{ item || 'не задано' }}</div>
          </template>
        </div>
      </div>
      <!--
      <div class="row">
        <div class="col-3">
          <div class="club-panel__label">Форматы тренировок</div>
          <div class="club-panel__tag-list">
            <template v-for="(item, index) in club.ages">
              <div class="club-panel__tag" :key="'ages-' + index">{{ ageList[item] || 'не задано' }}</div>
            </template>
          </div>
        </div>
        <div class="col-3">
          <div class="club-panel__label">Типы тренировок</div>
          <div class="club-panel__tag-list">
            <template v-for="(item, index) in club.types">
              <div class="club-panel__tag" :key="'types-' + index">{{ typeList[item] || 'не задано' }}</div>
            </template>
          </div>
        </div>
        <div class="col-12">

        </div>
      </div>
      -->
      <div class="club-panel__block">
        <div class="club-panel__label">Контакты</div>
        <div class="club-panel__contact-list">
          <div class="club-panel__contact">
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'club-panel__contact-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M26.272 25.467l-.048-.231c-.13-.754-3.652-2.794-3.961-2.895l-.26-.05c-.544-.041-1.233.259-2.695.914-1.197-.933-2.632-2.96-3.26-4.094-.681-1.229-1.414-3.187-1.507-4.528 1.665-.75 2.365-1.08 2.546-1.607.096-.286.412-4.05-.179-4.596l-.173-.175c-.367-.375-.923-.943-1.693-1a1.718 1.718 0 00-.563.053c-.57.154-1.982.601-2.803 1.46-.507.536-2.198 6.212 1.16 12.284 3.318 6.006 8.938 7.73 9.834 7.796l.024.002h.08c1.252-.072 2.5-.821 2.98-1.14.883-.59.647-1.632.518-2.193z" stroke="#CAF12E" stroke-width="1.5"/></svg>
            <div class="club-panel__contact-content">
              <div class="club-panel__contact-label">Телефон</div>
              <div class="club-panel__contact-value">
                <a :href="'tel:' + club.phone" class="club-panel__contact-link">{{ club.phone }}</a>
              </div>
            </div>
          </div>
          <div class="club-panel__contact">
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'club-panel__contact-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M26.272 25.467l-.048-.231c-.13-.754-3.652-2.794-3.961-2.895l-.26-.05c-.544-.041-1.233.259-2.695.914-1.197-.933-2.632-2.96-3.26-4.094-.681-1.229-1.414-3.187-1.507-4.528 1.665-.75 2.365-1.08 2.546-1.607.096-.286.412-4.05-.179-4.596l-.173-.175c-.367-.375-.923-.943-1.693-1a1.718 1.718 0 00-.563.053c-.57.154-1.982.601-2.803 1.46-.507.536-2.198 6.212 1.16 12.284 3.318 6.006 8.938 7.73 9.834 7.796l.024.002h.08c1.252-.072 2.5-.821 2.98-1.14.883-.59.647-1.632.518-2.193z" stroke="#CAF12E" stroke-width="1.5"/></svg>
            <div class="club-panel__contact-content">
              <div class="club-panel__contact-label">Сайт</div>
              <div class="club-panel__contact-value">
                <a :href="club.site" target="_blank" class="club-panel__contact-link">{{ club.site.replace(/(^\w+:|^)\/\//, '') }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="club-panel__contact-list">
          <div class="club-panel__contact">
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" v-bind:svg-inline="''" v-bind:class="'club-panel__contact-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="#CAF12E"><path d="M24.6 12.8c-.1 0-.1 0 0 0-.2.1-.4.2-.5.3-1.1.7-2.3 1.4-3.4 2.1-.3.2-.7.4-1 .6-1.9 1.2-3.7 2.3-5.6 3.5-.1.1-.2.1-.3.2-.2.1-.2.2-.2.4v.1c.2.5.3 1 .5 1.5v.1c.1.2.1.3.2.5l1 2.9v-.1-.1c0-.2 0-.4.1-.6 0-.2 0-.4.1-.6.1-.6.1-1.2.2-1.8v-.2c0-.1.1-.2.2-.3 1.1-.9 2.1-1.9 3.1-2.8.4-.3.7-.6 1.1-1 .1-.1.3-.2.4-.4l.2-.2c1.2-1.1 2.4-2.2 3.7-3.3l.3-.3.1-.1c.1-.1.1-.1.1-.2 0 0 0-.1.1-.1h-.1c-.1-.1-.2-.1-.3-.1z"/><path d="M29.3 8.5c-.8-.6-1.7-.5-2.5-.2-2.1.8-4.3 1.6-6.4 2.4-2.4.9-4.9 1.9-7.3 2.8-.4.1-.8.3-1.2.4-1.4.5-2.8 1.1-4.3 1.6-.5.2-.9.5-1.3.8-.4.4-.8 1-.7 1.7.1.7.5 1.2.9 1.5.4.2.7.4 1 .5l3.9 1.2c.3 1 .6 1.9.9 2.9.3.8.5 1.6.8 2.4.1.3.3.7.7 1 .4.3.8.4 1.2.4.9 0 1.6-.3 2.1-.8s1-1 1.6-1.5c.2.2.4.3.7.5 1 .8 2.1 1.5 3.1 2.3.7.5 1.6.9 2.6.5s1.5-1.3 1.7-2.1c1-4.7 2-9.3 3-14 0-.1 0-.2.1-.3.1-.5.2-1 .3-1.6.2-.9-.1-1.8-.9-2.4zm-.8 3.6c0 .1-.1.3-.1.4-1 4.7-2 9.3-3 14-.2 1.1-1 1.4-1.9.7-1-.8-2.1-1.5-3.1-2.3-.5-.4-1-.7-1.5-1.1l-.1-.1s-.1 0-.1-.1l-.8.8c-.4.4-.8.8-1.2 1.1l-.5.5c-.2.2-.4.3-.6.4h-.4c-.3 0-.4-.1-.5-.4-.1-.2-.2-.5-.2-.7-.1-.2-.1-.3-.2-.5-.1-.4-.2-.7-.4-1.1v-.1c-.1-.4-.3-.8-.4-1.2-.2-.7-.5-1.4-.7-2.1-.1-.2-.1-.2-.3-.3-.1 0-.3-.1-.4-.1l-3.9-1.2c-.2-.1-.5-.2-.6-.3-.3-.2-.3-.5-.1-.8.2-.2.5-.4.9-.5 1.4-.6 2.8-1.1 4.2-1.6.4-.1.8-.3 1.2-.4 2.4-.9 4.9-1.9 7.3-2.8 2.1-.8 4.3-1.6 6.4-2.4.9-.3 1.5.1 1.4 1.1-.2.2-.3.6-.4 1.1z"/></g></svg>
            <div class="club-panel__contact-content">
              <div class="club-panel__contact-label">Телеграм</div>
              <div class="club-panel__contact-value">
                <a :href="'tg://resolve?domain=' + club.contactTelegram" target="_blank" class="club-panel__contact-link">{{ club.contactTelegram }}</a>
              </div>
            </div>
          </div>
          <div class="club-panel__contact">
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'club-panel__contact-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M24.283 14.651l-4.741 3.838a2.46 2.46 0 01-3.053 0l-4.781-3.838" stroke="#CAF12E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path clip-rule="evenodd" d="M12.745 8.438h10.48c1.51.017 2.949.659 3.979 1.776a5.624 5.624 0 011.474 4.139v7.294a5.624 5.624 0 01-1.474 4.139 5.497 5.497 0 01-3.98 1.776H12.746c-3.245 0-5.432-2.654-5.432-5.915v-7.294c0-3.261 2.187-5.915 5.432-5.915z" stroke="#CAF12E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
            <div class="club-panel__contact-content">
              <div class="club-panel__contact-label">Почта</div>
              <div class="club-panel__contact-value">
                <a :href="'mailto:' + club.contactEmail" class="club-panel__contact-link">{{ club.contactEmail }}</a>
              </div>
            </div>
          </div>
          <div class="club-panel__contact">
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'club-panel__contact-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M28.282 12.42a.75.75 0 00-.698-1.167 4.66 4.66 0 00.244-.605.75.75 0 00-1.092-.879 7.447 7.447 0 01-1.97.8 4.838 4.838 0 00-3.192-1.194c-2.645 0-4.81 2.114-4.81 4.746l.001.081a10.827 10.827 0 01-6.935-3.824.75.75 0 00-1.223.096 4.679 4.679 0 00-.654 2.392c0 .757.18 1.473.5 2.108a.752.752 0 00-.533.7v.068c0 1.4.613 2.654 1.585 3.52a.75.75 0 00-.125.699 4.78 4.78 0 002.544 2.848 7.509 7.509 0 01-3.962.604.75.75 0 00-.487 1.38 12.369 12.369 0 006.623 1.91c7.957 0 12.3-6.504 12.3-12.119l-.002-.149a8.907 8.907 0 001.886-2.015z" stroke="#CAF12E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
            <div class="club-panel__contact-content">
              <div class="club-panel__contact-label">Твиттер</div>
              <div class="club-panel__contact-value">
                <a :href="club.contactTwitter" target="_blank" class="club-panel__contact-link">{{ club.contactTwitter }}</a>
              </div>
            </div>
          </div>
          <div class="club-panel__contact">
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'club-panel__contact-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M23.493 20.38c-.298-.148-1.764-.866-2.039-.969-.275-.097-.476-.148-.67.148-.2.297-.773.97-.945 1.163-.172.2-.35.223-.648.075-.297-.149-1.26-.462-2.4-1.477-.887-.787-1.483-1.762-1.66-2.059-.172-.296-.018-.456.131-.604.132-.131.298-.348.447-.519.149-.171.2-.297.298-.496.097-.2.051-.37-.023-.519-.075-.148-.67-1.614-.922-2.207-.241-.581-.487-.501-.67-.507-.172-.011-.373-.011-.573-.011a1.1 1.1 0 00-.796.37c-.275.297-1.043 1.015-1.043 2.48 0 1.466 1.071 2.874 1.22 3.074.149.2 2.102 3.199 5.098 4.487.71.308 1.266.49 1.701.628.716.228 1.363.194 1.879.12.573-.086 1.764-.72 2.016-1.415.246-.695.246-1.288.172-1.414-.075-.125-.275-.2-.573-.348z" fill="#CAF12E"/><path d="M12.602 27.045h0l.004.002c1.64.97 3.519 1.485 5.44 1.485 5.881 0 10.676-4.764 10.682-10.637v0c0-2.84-1.11-5.512-3.124-7.522l-.002-.002a10.658 10.658 0 00-7.55-3.114c-5.887 0-10.683 4.768-10.683 10.626 0 2.004.56 3.962 1.633 5.656l.06.096-.916 3.332-.344 1.253 1.257-.328 3.452-.902.09.055z" stroke="#CAF12E" stroke-width="1.5"/></svg>
            <div class="club-panel__contact-content">
              <div class="club-panel__contact-label">Whatsapp</div>
              <div class="club-panel__contact-value">
                <a :href="'https://wa.me/' + club.contactWhatsapp" target="_blank" class="club-panel__contact-link">{{ club.contactWhatsapp }}</a>
              </div>
            </div>
          </div>
          <div class="club-panel__contact">
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'club-panel__contact-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M25.061 12.235a1.296 1.296 0 11-2.592 0 1.296 1.296 0 012.592 0zm-7.06 9.365a3.6 3.6 0 110-7.2 3.6 3.6 0 010 7.2zm0-9.146a5.546 5.546 0 100 11.092 5.546 5.546 0 000-11.092zm0-3.308c2.883 0 3.224.011 4.363.063 1.053.048 1.625.224 2.006.372.504.196.863.43 1.241.808.378.378.613.738.808 1.242.148.38.324.952.372 2.005.052 1.14.063 1.48.063 4.364s-.01 3.226-.063 4.365c-.048 1.053-.224 1.625-.372 2.005-.195.504-.43.864-.808 1.242a3.344 3.344 0 01-1.241.808c-.38.148-.953.324-2.006.372-1.139.052-1.48.063-4.364.063s-3.225-.011-4.364-.063c-1.053-.048-1.625-.224-2.005-.372a3.346 3.346 0 01-1.242-.808 3.346 3.346 0 01-.808-1.242c-.148-.38-.324-.952-.372-2.005-.052-1.14-.063-1.48-.063-4.364s.01-3.226.063-4.365c.048-1.053.224-1.625.372-2.005.196-.504.43-.864.808-1.242a3.344 3.344 0 011.242-.808c.38-.148.952-.324 2.005-.372 1.139-.052 1.48-.063 4.364-.063zM18 7.2c-2.934 0-3.302.013-4.454.065-1.15.053-1.934.235-2.621.502a5.292 5.292 0 00-1.913 1.246c-.6.6-.97 1.203-1.246 1.913-.267.687-.45 1.472-.502 2.621C7.213 14.7 7.2 15.067 7.2 18c0 2.933.013 3.301.065 4.453.053 1.15.235 1.935.502 2.622.276.71.645 1.312 1.246 1.913.6.6 1.203.97 1.913 1.245.687.267 1.472.45 2.621.502 1.152.053 1.52.065 4.453.065 2.933 0 3.301-.012 4.453-.065 1.15-.052 1.935-.235 2.622-.502a5.295 5.295 0 001.913-1.245c.6-.6.97-1.203 1.245-1.913.267-.687.45-1.473.502-2.622.053-1.152.065-1.52.065-4.453 0-2.933-.012-3.3-.065-4.453-.052-1.15-.235-1.934-.502-2.621a5.295 5.295 0 00-1.245-1.913c-.6-.6-1.203-.97-1.913-1.246-.687-.267-1.473-.45-2.622-.502C21.301 7.213 20.933 7.2 18 7.2z" fill="#CAF12E"/></svg>
            <div class="club-panel__contact-content">
              <div class="club-panel__contact-label">Инстаграм</div>
              <div class="club-panel__contact-value">
                <a :href="club.contactInstagram" target="_blank" class="club-panel__contact-link">{{ club.contactInstagram }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="club-panel__block">
        <div class="club-panel__label">Удобства</div>
        <div class="club-panel__feature-list">
          <template v-for="(item, index) in club.feature">
            <div class="club-panel__feature"
              :key="'feature-' + index"
              :id="'tooltip-item' + index"
            >
              <img :src="item.img" />
              <b-tooltip :target="'tooltip-item' + index" variant="danger" placement="right">
                {{ item.name }}
              </b-tooltip>
            </div>
          </template>
        </div>
      </div>
    </div>
    <b-modal ref="modal" size="lg" centered hide-footer hide-header>
      <div class="modal-club">
        <div class="modal-club__control">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 15.5 15.5" v-bind:svg-inline="''" v-bind:class="'modal-club__icon'" @click="closeModal" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="#555"><path d="M.76 15.49a.75.75 0 01-.53-1.28l14-14a.75.75 0 011.06 1.06l-14 14a.75.75 0 01-.53.22z"/><path d="M14.75 15.5a.75.75 0 01-.53-.22l-14-14A.75.75 0 011.28.22l14 14a.75.75 0 01-.53 1.28z"/></g></svg>
        </div>
        <div class="modal-club__body">
          <div class="modal-club__img">
            <template v-if="club.img">
              <img :src="club.img" alt="photo">
            </template>
            <template v-else>
              <svg width="70" height="57" viewBox="0 0 70 57" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'card-club__ava'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M24.907 2.48a2.25 2.25 0 011.636-.705h16.354a2.25 2.25 0 011.663.735l5.852 6.423a3.75 3.75 0 002.773 1.225h12.534a2.25 2.25 0 012.25 2.25v40.41a2.25 2.25 0 01-2.25 2.25H4.28a2.25 2.25 0 01-2.25-2.25v-40.41a2.25 2.25 0 012.25-2.25h11.755a3.75 3.75 0 002.725-1.175l6.146-6.503zM26.543.275a3.75 3.75 0 00-2.726 1.174l-6.146 6.504a2.25 2.25 0 01-1.635.704H4.28a3.75 3.75 0 00-3.75 3.75v40.41a3.75 3.75 0 003.75 3.75H65.72a3.75 3.75 0 003.75-3.75v-40.41a3.75 3.75 0 00-3.75-3.75H53.185a2.25 2.25 0 01-1.664-.734L45.67 1.499A3.75 3.75 0 0042.897.275H26.543zm.63 29.542a7.548 7.548 0 1115.096 0 7.548 7.548 0 01-15.096 0zm7.548-9.047a9.048 9.048 0 100 18.095 9.048 9.048 0 000-18.095z" fill="#DEE4ED"/></svg>
            </template>
          </div>
          <div class="modal-club__content">
            <div class="modal-club__name">{{ club.name }}</div>
            <div class="modal-club__adress">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path clip-rule="evenodd" d="M8.24 14.392a7.678 7.678 0 1115.356.052v.087c-.052 2.756-1.591 5.304-3.479 7.295a20.18 20.18 0 01-3.59 2.957.93.93 0 01-1.218 0 19.819 19.819 0 01-5.052-4.73 9.826 9.826 0 01-2.018-5.635v-.026z" stroke="#5C5C5C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><circle cx="15.917" cy="14.539" r="2.461" stroke="#5C5C5C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
              {{ club.address }}
            </div>
            <div class="modal-club__text">{{ club.text }}</div>
            <div class="modal-club__close" @click="closeModal">Закрыть</div>
          </div>
        </div>
      </div>
    </b-modal>
  </ClubTemplate>
</template>

<script>
import ClubTemplate from './_component/ClubTemplate.vue';
import { getComfortItem } from './_component/helper';

export default {
  components: {
    ClubTemplate,
  },
  props: {
    clubId: {
      type: [String, Number],
      required: true
    }
  },
  data(){
    return {
      club: {},
      ageList: {},
      typeList: {}
    };
  },
  methods: {
    load(){
      RequestManager.Club.getClubById({ id: this.clubId }).then( (item) => {


        this.club = {
          img       : item.avatar,
          name      : item.name,
          address   : item.address,
          text      : item.about,
          ages      : item.ages, //TODO fix
          types     : item.types, //TODO fix
          tags      : item.tags.map(tagObject => tagObject.tag),
          phone     : item.phone,
          site      : item.site || '',
          contactTelegram  : '-',
          contactEmail     : '-',
          contactTwitter   : '-',
          contactWhatsapp  : '-',
          contactInstagram : '-',
          feature   : item.feature_set.map(tagObject => getComfortItem(tagObject.comfort)),
          contact_set: item.contact_set
        };

        for(let i = 0; i < this.club.contact_set.length; i++) {
          let obj = this.club.contact_set[i];
          switch (obj.type) {
            case 'TGRM': this.club.contactTelegram  = obj.value; break;
            case 'MAIL': this.club.contactEmail     = obj.value; break;
            case 'TWTR': this.club.contactTwitter   = obj.value; break;
            case 'WTSP': this.club.contactWhatsapp  = obj.value; break;
            case 'INST': this.club.contactInstagram = obj.value; break;
          }
        }

      });
    },
    showModal() {
      this.$refs['modal'].show();
    },
    closeModal() {
      this.$refs['modal'].hide();
    }
  },
  created() {
    this.load();
    RequestManager.Reference.getClubAgeList({}).then((list) => {
      const _ageList = {};
      list.forEach(i => _ageList[i.key] = i.value);
      this.ageList = _ageList;
    });
    RequestManager.Reference.getTrainGroupTypeList({}).then((list) => {
      const _typeList = {};
      list.forEach(i => _typeList[i.key] = i.value);
      this.typeList = _typeList;
    });
  }
};
</script>

<style lang="scss" scoped>
// club-panel
.club-panel {
  .club-panel__vue {
    display: flex;
    margin-bottom: 18px;
    .club-panel__photo {
      flex-shrink: 0;
      width: 200px;
      height: 200px;
      margin-right: 16px;
      border-radius: 8px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .club-panel__content {
      .club-panel__title {
        margin-bottom: 8px;
        color: var(--text-prime);
        font-weight: 500;
        font-size: 25px;
      }
      .club-panel__adress {
        margin-bottom: 12px;
        color: var(--text-descr);
        font-size: 17px;
      }
      .club-panel__text {
        max-height: 60px;
        margin-bottom: 10px;
        color: var(--text-descr);
        font-size: 17px;
        overflow: hidden;
      }
      .club-panel__more {
        color: var(--color-prime);
        font-size: 17px;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        &:hover {
          color: var(--color-prime-active);
        }
      }
    }
  }
  .club-panel__label {
    margin-bottom: 4px;
    color: var(--text-prime);
    font-size: 17px;
  }
  .club-panel__tag-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 12px;
    .club-panel__tag {
      margin-bottom: 4px;
      margin-right: 4px;
      padding: 9px 12px;
      color: var(--text-sub);
      font-weight: 500;
      font-size: 17px;
      border: 1px solid var(--text-sub);
      border-radius: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .club-panel__block {
    .club-panel__label {
      margin-bottom: 20px;
    }
  }
  .club-panel__contact {
    display: flex;
    margin-bottom: 20px;
    .club-panel__contact-icon {
      flex-shrink: 0;
      margin-right: 4px;
    }
    .club-panel__contact-content {
      .club-panel__contact-label {
        color: var(--text-descr);
        font-size: 14px;
      }
      .club-panel__contact-value {
        color: var(--text-prime);
        font-size: 17px;
      }
    }
    .club-panel__contact-link {
      color: var(--color-prime);
      &:hover {
        color: var(--color-prime-active);
      }
    }
  }
  .club-panel__contact-list {
    display: flex;
    flex-wrap: wrap;
    .club-panel__contact {
      margin-right: 24px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .club-panel__feature-list {
    display: flex;
    flex-wrap: wrap;
    .club-panel__feature {
      position: relative;
      cursor: pointer;
      margin-right: 4px;
      &:last-child {
        margin-right: 0;
      }
      // &::after{
      //   content: "";
      //   display: block;
      //   width: 32px;
      //   height: 32px;
      //   position: absolute;
      //   background: #1b1b1ba6;
      //   top: 0;
      // }
    }
  }
}

// modal-club
.modal-club {
  position: relative;
  .modal-club__body {
    display: flex;
    .modal-club__img {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 200px;
      height: 200px;
      border-radius: 8px;
      background-color: var(--br-block-sub);
      overflow: hidden;
      .modal-club__ava {
        width: 40px;
        height: auto;
        path {
          fill: var(--text-descr);
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .modal-club__content {
      padding: 10px 16px;
      .modal-club__adress {
        margin-bottom: 12px;
        color: var(--text-descr);
        font-size: 17px;
      }
      .modal-club__name {
        margin-bottom: 8px;
        color: var(--text-prime);
        font-weight: 500;
        font-size: 25px;
      }
      .modal-club__text {
        margin-bottom: 10px;
        color: var(--text-descr);
        font-size: 17px;
      }
      .modal-club__close {
        color: var(--color-prime);
        font-size: 17px;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        &:hover {
          color: var(--color-prime-active);
        }
      }
    }
  }
  .modal-club__control {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    .modal-club__icon {
      cursor: pointer;
      path {
        transition: all .2s ease-in-out;
      }
      &:hover {
        path {
          fill: var(--color-prime);
        }
      }
    }
  }
}
</style>

