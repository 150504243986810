<template>
  <div class="container">
    <div class="page-header">
      <div class="page-header__ell">
        <h1 class="page-title">Клуб</h1>
      </div>
      <div class="page-header__ell">
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 12.05 11.93" v-if="$route.name === 'CLUB_VIEW'" v-bind:svg-inline="''" v-bind:class="'edit-club'" @click="editClub" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11.3 11.93H7.05a.75.75 0 010-1.5h4.25a.75.75 0 010 1.5zM1.26 11.93a.75.75 0 01-.73-.58L0 9.28a1.74 1.74 0 01.37-1.47L5.9.76a2 2 0 013-.23l.93.73a2 2 0 01.49 2.93l-5.57 7.05a1.73 1.73 0 01-1.35.67H1.27zM7.08 1.68l-5.53 7a.24.24 0 000 .2l.35 1.49h1.53a.24.24 0 00.19-.09l5.47-7.02c.28-.36-.07-.7-.23-.83l-.93-.73c-.13-.1-.57-.4-.86 0z"/><path d="M8.65 5.8a.75.75 0 01-.46-.16L5 3.13A.75.75 0 015.91 2l3.2 2.51a.75.75 0 01-.46 1.29z"/></svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 12.81 13.83" v-bind:svg-inline="''" v-bind:class="'delete-club'" @click="deleteClub" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6.38 13.83H3.76A2.22 2.22 0 011.5 11.7C1.29 9.8.93 5.33.93 5.29a.75.75 0 111.5-.12s.36 4.49.57 6.36c.07.67.46.77.8.77h5.19a.75.75 0 00.84-.78c.21-1.86.57-6.31.57-6.36a.76.76 0 01.81-.69.75.75 0 01.69.81s-.37 4.52-.58 6.4A2.24 2.24 0 019 13.81zM12.06 3.83H.75a.75.75 0 010-1.5h11.31a.75.75 0 010 1.5z"/><path d="M9.88 3.83a1.85 1.85 0 01-1.81-1.49l-.17-.81a.2.2 0 00-.09 0H5a.1.1 0 00-.1.08l-.15.76a1.85 1.85 0 01-1.82 1.46.75.75 0 010-1.5.35.35 0 00.34-.33l.16-.81A1.62 1.62 0 015 0h2.81a1.6 1.6 0 011.55 1.19l.18.81a.35.35 0 00.34.28.75.75 0 110 1.5z"/></svg>
      </div>
    </div>

    <div class="d-flex justify-content-between">
      <div>
        <ClubTab :clubId="clubId" />
      </div>
      <div v-if="add" @click="$emit('clickAdd')">
        <div class="add-link" style="margin-top: 15px;">
          {{ add }}
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><rect width="28" height="28" rx="14" fill="#E54C4C"/><path d="M14 6.5v15M21.5 14h-15" stroke="#161616" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>
      </div>
    </div>
    <slot/>
  </div>
</template>

<script>
import ClubTab from "@page/Club/_component/ClubTab";

export default {
  name: "ClubTemplate",
  components: {
    ClubTab,
  },
  props: {
    add: { type: String, default: '' },
    clubId: {
      type: [ String, Number ],
      required: true
    }
  },
  methods: {
    deleteClub() {
      const options = {title: 'Удалить клуб?', cancelLabel: 'Нет', okLabel: 'Да'};
      this.$dialogs.confirm('Удалить клуб?', options)
        .then((res) => { // {ok: true|false|undefined}
          if (res && res.ok) {
            RequestManager.Club.deleteClubById({id: this.clubId}).then(() => {
              this.$toasts.push({
                message: 'Клуб успешно удален',
                duration: 4000,
              });
              this.$router.push({name: this.$routeName.CLUB_LIST });
            }, () => {
            });
          }
        });
    },
    editClub() {
      this.$router.push({ name: this.$routeName.CLUB_EDIT, params: { clubId: this.clubId } });
    }
  }
};

</script>

<style lang="scss" scoped>
// delete/edit-club
.delete-club,
.edit-club {
  width: 16px;
  height: auto;
  cursor: pointer;
  path {
    fill: var(--color-sub);
    transition: all .2s ease-in-out;
  }
  &:hover {
    path {
      fill: var(--color-sub-active);
    }
  }
}
.edit-club {
  margin-right: 12px
}

</style>
